import React from 'react';
import { Text, View, ScrollView, StyleSheet, Button, Image } from 'react-native';
import Moment from 'moment';
//colors
import colors from '../Colors'
const theme = colors.defaultTheme();

export default function PlayerProfile({ navigation, route }) {

    let player = route.params
    let birth = ""

    if (player.player_detail) {

        player = player.player_detail[0]
        birth = Moment(player.birthdate).format('MM/DD/YYYY')

    } else {

        birth = "--"

    }

    return (

        <View style={styles.container}>
            <Text style={styles.title}>{player.name}</Text>
            <ScrollView style={styles.container}>
                <Text style={styles.text}>Birth: {birth} </Text>
                <Text style={styles.text}>Sport: {player.sport}</Text>
            </ScrollView>
        </View>


    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: 100,
        backgroundColor: theme.BACKGROUND_ONE,
        alignSelf: 'stretch',
        textAlign: 'center',
        flexDirection: 'column'
    },
    title: {
        padding: 20,
        fontSize: 22,
        color: theme.TITLE_COLOR,
        backgroundColor: theme.PRIMARY_BLUE,
        borderBottomWidth: 1,
        borderBottomColor: theme.BACKGROUND_TWO,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    text: {
        color: theme.TEXT_COLOR,
        fontSize: 22,
        paddingLeft: 20,
        textAlign: 'left',
    },
    image: {
        width: 65,
        height: 90,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 500,
        alignSelf: 'center',


    },
    horizontal: {
        flexDirection: "row",
    }
});