import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, View, Button, Pressable, TouchableOpacity, Image, Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {createStackNavigator} from '@react-navigation/stack';
import useStore from "./services/StoreService"
import { FontAwesome, MaterialIcons } from '@expo/vector-icons'
import Login from "./pages/Login"
import Search from "./pages/Search"
import SignUp from './pages/Signup'
import PlayersList from './pages/PlayersList'
import PlayerProfile from './pages/PlayerProfile'

//colors
import colors from './Colors'
const theme = colors.defaultTheme();

function HomeScreen({ navigation }) {
  return (
    <View style={styles.container}>
      <Text style={styles.bigTitle}>SportsBox</Text>
      <Text style={styles.title}>Welcome to SportsBox</Text>
      <TouchableOpacity style={styles.button} title="Players list" onPress={() => navigation.navigate('Players List')}>
        <Text style={styles.buttonText} >Players list</Text>
      </TouchableOpacity>
    </View>
  );
}

function LoginScreen({ navigation }) {

  const loggedIn = useStore((state) => state.isLoggedIn)

  // useEffect(() => {
  //   if (loggedIn) {
  //     navigation.navigate('Players List')
  //   }
  // }, [loggedIn])

  return (
    <View style={styles.container}>
      <Login navigation={navigation} />
    </View>
  )
}

function SignUpScreen({ navigation }) {

  return (
    <View style={styles.container}>
      <SignUp navigation={navigation} />
    </View>
  )
}



function UserScreen({ navigation }) {

  const logOut = useStore((state) => state.resetUser)
  const email = useStore((state) => state.email)
  const loggedIn = useStore((state) => state.isLoggedIn)

  useEffect(() => {
    if (!loggedIn) {
      navigation.replace('Login')
    }
  }, [loggedIn])
  
  return (
    <View style={styles.container}>
      <Text style={styles.userTitle}>{email}</Text>
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          logOut();
          navigation.navigate("Players List");
        }}
      >
        <Text style={styles.buttonText}>Logout</Text>
      </TouchableOpacity>
    </View>
  );
}

const Stack = createStackNavigator();

const stackOptions = (navigation) => {

  return {
    headerTitle: () =>  <Image
    resizeMode="contain"
    style={{height: 32 }}
    source={require('./assets/sblogo.png')}
  />,
    headerRight: () => (
      <View style={styles.listHeader}>
        <Pressable onPress={() => navigation.push("Search")} style={styles.pressable}>
          <MaterialIcons name="search" size={28} color={theme.TEXT_COLOR} />
        </Pressable>
        <Pressable onPress={() => navigation.push("Your profile")}>
          <FontAwesome name="user-circle" size={24} color={theme.TEXT_COLOR} />
        </Pressable>
      </View>
    ),
    headerShown: true,
    headerTintColor: theme.HEADER_TITLE_COLOR,
    orientation: 'all',
    headerTitleAlign: "center",
    headerMode: 'screen',
    headerStyle: {
      backgroundColor: theme.HEADER_COLOR,
    }
  }

}

const defaultScreenOptions = (navigation) => {

  return {
    headerShown: true,
    headerTintColor: theme.HEADER_TITLE_COLOR,
    headerBackTitle: "Back",
    headerBackTitleVisible: true,
    headerTruncatedBackTitle: "Back",
    orientation: 'all',
    headerTitleAlign: "center",
    headerMode: 'screen',
    headerStyle: {
      backgroundColor: theme.HEADER_COLOR
    }
  }

}


function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Players List" component={PlayersList} options={({ navigation }) => stackOptions(navigation)}/>
        <Stack.Screen name="Search" component={Search} options={defaultScreenOptions}/>
        <Stack.Screen name="Player Profile" component={PlayerProfile} options={defaultScreenOptions}/>
        <Stack.Screen name="Login" component={LoginScreen} options={defaultScreenOptions}/>
        <Stack.Screen name="Sign Up" component={SignUpScreen} options={defaultScreenOptions}/>
        <Stack.Screen name="Your profile" component={UserScreen} options={defaultScreenOptions}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.BACKGROUND_ONE,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    color: theme.TITLE_COLOR,
    fontSize: 18,
    padding: 20,
    textAlign: 'center'
  },
  bigTitle: {
    color: theme.PRIMARY_BLUE,
    fontSize: 45,
    textAlign: 'center'

  },
  userTitle: {
    color: theme.TITLE_COLOR,
    fontSize: 25,
    textAlign: 'center',
    flexDirection: "row",
    marginBottom:10
  },
  text: {
    color: theme.TEXT_COLOR,
    fontSize: 18
  },
  listHeader: {
    flexDirection: "row",
    marginRight: 10,
  },
  pressable: {
    marginRight: 10
  },

  button: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.PRIMARY_BLUE,
    alignSelf: 'center',
    borderRadius:10,
    paddingVertical:10,
    paddingHorizontal:20
},
buttonText: {
    fontSize: 14,
    color: "white",
    fontWeight: 'bold',
    textTransform:"uppercase"
},

})

export default App;

