import { Appearance } from 'react-native';
const colorScheme = Appearance.getColorScheme();

console.log(colorScheme)

let colors = {
    defaultTheme: () => {
        if(colorScheme == "dark"){
            return colors["dark"]
        } else if(colorScheme == "light") {
            return colors["light"]
        }       
    },
    light: {
        mode:"light",
        STATUS_BAR:"#FAFAFA",
        HEADER_COLOR: "#FAFAFA",
        HEADER_BUTTON_LABEL_COLOR:"#89b086",
        PRIMARY_BLUE:"red",
        NORMAL_PLAYER_NAME:"#999",
        UNFOLLOW_BUTTON:"#e1e1e1",
        NATURAL_BLUE: "blue",
        BACKGROUND_ONE: "#FAFAFA",
        BACKGROUND_TWO: "#F3F3F3",
        HEADER_TITLE_COLOR: "#080808",
        TITLE_COLOR: "#000",
        TEXT_NEGATIVE: "#F1F1F1",
        TEXT_COLOR: "#000",
        LINE_GRAY: "#CCCCCC",
        LINE_LIGHT: "#e1e1e1",
        ICON_COLOR: "#616161",
        ICON_ACTIVE_COLOR: "#080808",
        TAB_BG: "#FAFAFA",
        CAMERA_ICONS_COLOR: "#fff",
        BUTTON_COLOR:"#e1e1e1",
        BUTTON_TEXT_COLOR:"#666",
        BUTTON_TEXT_ACTIVE_COLOR:"#000",
        BUTTON_ACTIVE_COLOR:"#b6fc03",
        EDIT_PROFILE_BUTTON:"#FAFAFA",
        PHOTO_SELECTED:"#rgba(181, 252, 3,0.3)",
        PHOTO_SELECTED_LINE:"#b6fc03",
        SECTION_TITLE:"#2196f3",
        PLACEHOLDER_COLOR: "#666",
        EMPTYSTAR_COLOR: "#e1e1e1",
        FULLSTAR_COLOR: "#89b086",
        PAGINATION_INACTIVE_COLOR: "#e5e5e5",
        PAGINATION_ACTIVE_COLOR: "#000",
        INPUT_BORDER_COLOR:"#e1e1e1",
        LOGIN_TEXT_COLOR:"#080808",
        CURRENT_STORE_SELECTED:"#f1f1f1",
        STORE_ICON_COLOR:"#5c788a",
        STORE_ICON_ACTIVE_COLOR:"#f55",
        LINK_COLOR:"#00d455",
        SL_BUTTON_COLOR:"#89b086",
        SL_BUTTON_TEXT_COLOR:"#FFF",
        NAVTAB_BG:"#FAFAFA",
        NAVTAB_INDICATOR:"#2196f3",
        NAVTAB_INACTIVE:"#999",
        LOGIN_LINK:"#2196f3",
        BACKGROUND_SEARCH_MODAL:"#f1f1f1",
        SLIDER_TRACKING_COLOR:"#89b086",
        SLIDER_BULLET:"#89b086"
    },
    dark: {
        mode:"dark",
        STATUS_BAR:"#040404",
        HEADER_COLOR: "#040404",
        HEADER_BUTTON_LABEL_COLOR:"#89b086",
        PRIMARY_BLUE:"red",
        NORMAL_PLAYER_NAME:"#999",
        UNFOLLOW_BUTTON:"#2e2d2d",
        NATURAL_BLUE: "blue",
        BACKGROUND_ONE: "#040404",
        BACKGROUND_TWO: "#080808",
        TITLE_COLOR: "#fff",
        HEADER_TITLE_COLOR: "#ccc",
        TEXT_NEGATIVE: "#080808",
        TEXT_COLOR: "#e1e1e1",
        LINE_GRAY: "#666",
        LINE_LIGHT: "#e1e1e1",
        ICON_COLOR: "#ccc",
        ICON_ACTIVE_COLOR: "#fff",
        TAB_BG: "#080808",
        CAMERA_ICONS_COLOR: "#fff",
        BUTTON_COLOR:"#121212",
        BUTTON_TEXT_COLOR:"#fff",
        BUTTON_TEXT_ACTIVE_COLOR:"#000",
        BUTTON_ACTIVE_COLOR:"#b6fc03",
        EDIT_PROFILE_BUTTON:"#080808",
        PHOTO_SELECTED:"#rgba(181, 252, 3,0.3)",
        PHOTO_SELECTED_LINE:"#b6fc03",
        SECTION_TITLE:"#2196f3",
        PLACEHOLDER_COLOR: "#999",
        EMPTYSTAR_COLOR: "#333",
        FULLSTAR_COLOR: "#89b086",
        PAGINATION_INACTIVE_COLOR: "#333",
        PAGINATION_ACTIVE_COLOR: "#fff",
        INPUT_BORDER_COLOR:"#333",
        LOGIN_TEXT_COLOR:"#080808",
        CURRENT_STORE_SELECTED:"rgba(255, 255, 255, 0.1)",
        STORE_ICON_COLOR:"#89b086",
        STORE_ICON_ACTIVE_COLOR:"#963f3f",
        LINK_COLOR:"#00d455",
        SL_BUTTON_COLOR:"#89b086",
        SL_BUTTON_TEXT_COLOR:"#FFF",
        NAVTAB_BG:"#333",
        NAVTAB_INDICATOR:"#2196f3",
        NAVTAB_INACTIVE:"#999",
        LOGIN_LINK:"#b6fc03",
        BACKGROUND_SEARCH_MODAL:"#666",
        SLIDER_TRACKING_COLOR:"#89b086",
        SLIDER_BULLET:"#89b086"
    },
}
export default colors