import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import DefaultStorage from './DataStorage';
import {Platform} from 'react-native';

import _, { floor } from 'lodash';
import { follow } from "./PlayerService";

function sortPlayersByCount(fPlayers, tPlayers) {
  for (let i = 0; i < fPlayers.length; i++) {
    const fPlayer = fPlayers[i];
    fPlayer.count = 0  

    if (tPlayers[fPlayer.id]) {
      fPlayers[i].count = tPlayers[fPlayer.id].count;
      fPlayers[i].summary = tPlayers[fPlayer.id].last_video.summary;
      fPlayers[i].publishedAt = tPlayers[fPlayer.id].last_video.publishedAt;
    }
  }
  fPlayers.sort(function (a, b) { return b.count - a.count })
  return fPlayers;
}

const useStore = create( persist((set, get) => ({
  email: "",
  authToken: "",
  players: [],
  playersMap: {},
  filters:[],
  trendingPlayers: [],
  trendingPlayersMap: {},
  isLoggedIn: false,
  setFilters: (filters ) => {   
    set((state) => ({
      filters: filters,
    }));
  },
  addPlayer: (player) => {
 
    set((state) => {
      const playersMap = _.cloneDeep(state.playersMap);
      const playerExists = playersMap[player.id] != null;
      if (!playerExists) {
        playersMap[player.id] = player;
      }

      let filterPlayers = playerExists ? get().players : [...get().players, player]
      filterPlayers = sortPlayersByCount(filterPlayers, get().trendingPlayersMap)

      return {
        players: filterPlayers,
        playersMap: playersMap,
      };
    });
  },
  setTrendingPlayers: ({trendingPlayers}) => {
    set((state) => {
      const trendingPlayersMap = {};
      let followedPlayers = [...state.players];
      const followedPlayersMap = _.cloneDeep(state.playersMap);

      for (let i = 0; i < followedPlayers.length; i++) {
        followedPlayers[i].summary = null,
        followedPlayers[i].publishedAt = null
        followedPlayers[i].count = 0
      }
      Object.keys(followedPlayersMap).forEach(function(key, index) {
        followedPlayersMap[key].summary = null
        followedPlayersMap[key].publishedAt = null
        followedPlayersMap[key].count = 0
      });
      
      
      for (let i = 0; i < trendingPlayers.length; i++) {
        const tplayer = trendingPlayers[i];
        trendingPlayersMap[tplayer.player_detail[0].id] = tplayer;
        const fPlayerIndex = followedPlayers.findIndex(
          (fPlayer) => tplayer.player_detail[0].id == fPlayer.id
        );
        if (fPlayerIndex >= 0) {
          followedPlayers[fPlayerIndex].summary = tplayer.last_video.summary;
          followedPlayers[fPlayerIndex].publishedAt =
            tplayer.last_video.publishedAt;
          followedPlayers[fPlayerIndex].count = tplayer.last_video.count;
          followedPlayersMap[followedPlayers[fPlayerIndex]] =
            followedPlayers[fPlayerIndex];
        }
      }

      followedPlayers = sortPlayersByCount(followedPlayers, trendingPlayersMap);
      return {
        trendingPlayers: trendingPlayers,
        trendingPlayersMap: trendingPlayersMap,
        players: followedPlayers,
        playersMap: followedPlayersMap
    }});
  },
  removePlayer: (player) => {
    set((state) => {
      const playersMap = _.cloneDeep(state.playersMap);
      if (playersMap[player.id]) {
        delete playersMap[player.id]
      }

      return {
      players: [...state.players.filter((p) => p.id !== player.id)],
      playersMap: playersMap
      }
    })
  },
  playerExists: (playerId) => {
    let playerExists = false;
    const players = get().players;
    for (let i = 0; i < players.length; i++) {
      if (players[i].id === playerId) {
        playerExists = true;
      }
    }
    return playerExists;
  },
  setUser: ({ authToken, email, players }) => {
    const tPlayers = get().trendingPlayersMap;
    const newPlayers = sortPlayersByCount(players, tPlayers);
    const playersMap = {}
    newPlayers.map((player => {
      playersMap[player.id] = player;
    }));
   
    set((state) => ({
      email: email,
      authToken: authToken,
      players: newPlayers,
      playersMap: playersMap,
      isLoggedIn: true,
    }));
  },
  resetUser: () => {
    set((state) => ({
      email: "",
      authToken: "",
      players: [],
      playersMap: {},
      isLoggedIn: false,
    }));
  },
}), {
  name: 'users',
  storage: createJSONStorage(() => DefaultStorage)
}));


export default useStore;
