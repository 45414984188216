import React, { useState, useEffect, useRef } from 'react';
import { Text, View, FlatList, StyleSheet, TouchableOpacity, Button, Pressable, SafeAreaView, StatusBar, useColorScheme } from 'react-native';
import axios from 'axios';
import LoadingView from '../components/Loading'
import useStore from "../services/StoreService"
import { follow, unfollow } from "../services/PlayerService"
import { MaterialIcons, Fontisto, MaterialCommunityIcons } from '@expo/vector-icons'
import DropDownPicker from 'react-native-dropdown-picker';
import moment from 'moment';

import {saveUserDataToSharedStorage, loadUserDataFromSharedStorage, reloadWidget, initBackgroundTask} from '../services/WidgetService';

const STYLES = ['default', 'dark-content', 'light-content'];
//colors
import colors from '../Colors'
const theme = colors.defaultTheme();
const appGroupIdentifier = 'group.com.airrostudio.sportsbox.SportsBoxApp';

export default function PlayersList({ navigation }) {

  const [showTrendingPlayers, setShowTrendingPlayers] = useState(true)
  const [loading, setLoading] = useState(false)
  const colorScheme = useColorScheme();

  const followingPlayers = useStore((state) => state.players)
  const trendingPlayers = useStore((state) => state.trendingPlayers)
  const trendingPlayersMap = useStore((state) => state.trendingPlayersMap)
  const setTrendingPlayers = useStore((state) => state.setTrendingPlayers)
  const authToken = useStore((state) => state.authToken)
  const loggedIn = useStore((state) => state.isLoggedIn)
  const playerExists = useStore((state) => state.playerExists)
  const addPlayer = useStore((state) => state.addPlayer)
  const removePlayer = useStore((state) => state.removePlayer)
  const setFilters = useStore((state) => state.setFilters)
  const filters = useStore((state) => state.filters)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [items, setItems] = useState([
    { label: 'Soccer', value: 'soccer' },
    { label: 'Basketball', value: 'basketball' },
    { label: 'Football', value: 'football' },
    { label: 'Hockey', value: 'hockey' },
    { label: 'Baseball', value: 'baseball' }
  ]);
  const didInit = useRef(false);

  async function onRefresh() {
    setIsRefreshing(true)
    if (showTrendingPlayers) {
      loadTrendingPlayers()
    } else {
      loadFollowingPlayers()
    }
    await updateWidgetInfo();
    setIsRefreshing(false)
  }

  function loadFollowingPlayers() {
    if (loggedIn) {
      setShowTrendingPlayers(false)
    } else {
      navigation.navigate('Login')
    }
  }

  function loadTrendingPlayers() {
    setShowTrendingPlayers(true)
    if (!trendingPlayers || trendingPlayers.length == 0) {
      setLoading(true)
    }

    const fetchData = async () => {
      try {
        const { data: response } = await axios.get('https://api.sportsbox.network/api/players/trending')
        const players = response.data
        setTrendingPlayers({ trendingPlayers: players })
        setLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    fetchData()
  }

  useEffect(() => {
    loadTrendingPlayers()
  }, []);

  useEffect(() => {
    setFilters(value)
  }, [value]);

  useEffect(() => {
    onRefresh()
  }, [filters]);

  useEffect(() => {
    async function updateWidget() {
      await updateWidgetInfo();
    }
    updateWidget();
    if (loggedIn) {
      setShowTrendingPlayers(false)
    } else {
      setShowTrendingPlayers(true)
    }

  }, [loggedIn]);

  useEffect(() => {
    if (!didInit.current) {
      initBackgroundTask(() => updateWidgetInfo());
      didInit.current = true;
    }
  }, []);

  async function updateWidgetInfo() {
    // console.log("Called update widget info");
    let trendingPlayers = await fetchTrendingPlayers();
    setTrendingPlayers({ trendingPlayers: trendingPlayers });
    const playersWithSummariesMap = {};
    let playersWithSummaries = trendingPlayers.filter((player) => {
      return player.last_video.summary && player.last_video.summary.length > 0;
    });

    for (let i = 0; i < playersWithSummaries.length; i++) {
      playersWithSummariesMap[playersWithSummaries[i].id] =
        playersWithSummariesMap[i];
    }

    let headlines = [];

    if (loggedIn && followingPlayers) {
      followingPlayers.map((player) => {
        if (player.summary && player.summary.length > 0) {
          headlines.push({
            player: player.name,
            headline: player.summary,
          });
        }
      });
    } else {
      headlines = playersWithSummaries.map((player) => {
        return {
          player: player.player_detail[0].name,
          headline: player.last_video.summary,
        };
      });
    }

    if (headlines.length > 0) {
      await saveUserDataToSharedStorage(headlines);
      // await loadUserDataFromSharedStorage();
    } else {
      // let currentData = await loadUserDataFromSharedStorage();
      // console.log("CURRENT DATA IS", currentData);
      // if (!currentData || !currentData.headline) {
      //   await saveUserDataToSharedStorage(newsData);
      // }
    }
    reloadWidget();
  }

  async function fetchTrendingPlayers() {
    try {
      const trendingPlayers = await axios.get(
        'https://api.sportsbox.network/api/players/trending',
      );
      return trendingPlayers.data.data;
    } catch (e) {
      return [];
    }
  }

  if (loading != "") { return (<LoadingView message={loading} />) }

  function dryTimeAgo(text) {

    let dryText = text.replace("hours", "H").replace(/\s/g, "").replace("aday", "1D").replace("days", "D").replace("minutes", "M")

    if (text != "Invalid date") {
      return dryText;
    } else {
      return "";
    }

  }

  const listNames = (item) => {

    let player = ""
    let summary = ""

    if (showTrendingPlayers == true) {
      let dateSource = item.last_video.publishedAt
      const date = new Date(dateSource)
      const timeago = moment(date).fromNow(true);
      player = item.player_detail[0].name
      summary = item.last_video.summary ? item.last_video.summary : ""
      if (item.count >= 4) {
        return (
          <View style={[styles.superTrendingItem]}>
            <Text style={styles.superTrending}><MaterialCommunityIcons name="fire" size={20} color="#f75b00" /> {player} <Text style={{ fontSize: 12, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={styles.text}>{summary}</Text>

          </View>
        )
      } else if (item.count >= 3 && item.count < 4) {
        return (
          <View style={styles.trendingItem}>
            <Text style={styles.trending}>{player} <Text style={{ fontSize: 14, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={[styles.text, { color: theme.TEXT_COLOR, fontSize: 16, lineHeight: 22 }]}>{summary}</Text>
          </View>
        )
      } else {
        return (
          <View>
            <Text style={[styles.itemText, { fontSize: 14, fontWeight: "bold", color: theme.NORMAL_PLAYER_NAME }]}>{player} <Text style={{ fontSize: 12, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={[styles.text, { fontSize: 12, fontWeight: "600", lineHeight: 16, color: theme.TEXT_COLOR }]}>{summary}</Text>
          </View>


        )
      }

    } else {
      let timeago = "";

      if (item.publishedAt) {
        let dateSource = item.publishedAt
        const date = new Date(dateSource)
        timeago = moment(date).fromNow(true);
      }

      player = item.name
      summary = item.summary

      if (item.count >= 4) {

        return (
          <View style={[styles.superTrendingItem]}>
            <Text style={styles.superTrending}><MaterialCommunityIcons name="fire" size={20} color="#f75b00" /> {player} <Text style={{ fontSize: 12, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={styles.text}>{summary}</Text>
          </View>
        )

      } else if (item.count >= 3 && item.count < 4) {

        return (
          <View style={styles.trendingItem}>
            <Text style={styles.trending}>{player} <Text style={{ fontSize: 14, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={[styles.text, { color: theme.TEXT_COLOR, fontSize: 16, lineHeight: 20 }]}>{summary}</Text>
          </View>
        )

      } else {

        return (

          <View>
            <Text style={[styles.itemText, { fontSize: 14, fontWeight: "bold", color: theme.NORMAL_PLAYER_NAME }]}>{player} <Text style={{ fontSize: 12, fontWeight: "300", color: theme.TEXT_COLOR }}> {dryTimeAgo(timeago)}</Text></Text>
            <Text style={[styles.text, { fontSize: 12, fontWeight: "600", lineHeight: 16, color: theme.TEXT_COLOR }]}>{summary}</Text>
          </View>

        )

      }
    }
  }

  function renderItem(item) {

    let sport = item.sport ? item.sport : item.player_detail[0].sport
    const currentFilter = filters.includes(sport)

    //console.log(filters, sport, currentFilter)

    if (filters.length > 0 && currentFilter == true) {

      return (
        <View style={styles.itemContainer}>
          <TouchableOpacity onPress={() => navigation.push('Player Profile', item)} style={styles.item}>
            {listNames(item)}
          </TouchableOpacity>
          {loggedIn && playerExists(showTrendingPlayers == true ? item.player_detail[0].id : item.id) && (

            <Pressable style={styles.itemActionUnfollow}
              onPress={async () => {
                try {
                  const { data } = await unfollow(showTrendingPlayers == true ? item.player_detail[0].id : item.id, authToken)
                  removePlayer(data.player)
                } catch (e) {
                  console.log(e)
                }
              }}>
              <Text style={styles.itemActionUnfollowText}>unfollow</Text>
            </Pressable>
          )
          }
          {loggedIn && !playerExists(showTrendingPlayers == true ? item.player_detail[0].id : item.id) && (

            <Pressable style={styles.itemActionFollow}
              onPress={async () => {
                try {
                  const { data } = await follow(showTrendingPlayers == true ? item.player_detail[0].id : item.id, authToken)
                  addPlayer(data.player)
                } catch (e) {
                  console.log(e)
                }
              }}>
              <Text style={styles.itemActionFollowText}>follow</Text>
            </Pressable>
          )
          }
        </View>
      )

    } else if (filters.length <= 0 && currentFilter == false) {

      return (
        <View style={styles.itemContainer}>
          <View style={styles.item}>
            {listNames(item)}
          </View>
          {loggedIn && playerExists(showTrendingPlayers == true ? item.player_detail[0].id : item.id) && (

            <Pressable style={styles.itemActionUnfollow}
              onPress={async () => {
                try {
                  const { data } = await unfollow(showTrendingPlayers == true ? item.player_detail[0].id : item.id, authToken)
                  removePlayer(data.player)
                } catch (e) {
                  console.log(e)
                }
              }}>
              <Text style={styles.itemActionUnfollowText}>unfollow</Text>
            </Pressable>
          )
          }
          {loggedIn && !playerExists(showTrendingPlayers == true ? item.player_detail[0].id : item.id) && (

            <Pressable style={styles.itemActionFollow}
              onPress={async () => {
                try {
                  const { data } = await follow(showTrendingPlayers == true ? item.player_detail[0].id : item.id, authToken)
                  addPlayer(data.player)
                } catch (e) {
                  console.log(e)
                }
              }}>
              <Text style={styles.itemActionFollowText}>follow</Text>
            </Pressable>
          )
          }
        </View>
      )

    }

  }

  function ListHeader() {

    return (
      <View style={{ marginBottom: 10, backgroundColor: theme.BACKGROUND_ONE }}>
        <View style={styles.listHeader}>
          <Pressable onPress={() => loadFollowingPlayers()} style={showTrendingPlayers == false ? styles.tab : styles.tabDisabled}>
            <Text style={showTrendingPlayers == false ? styles.tabText : [styles.tabText, { opacity: 0.5 }]}>Following</Text>
          </Pressable>
          <Pressable onPress={() => loadTrendingPlayers()} style={showTrendingPlayers == true ? styles.tab : styles.tabDisabled}>
            <Text style={showTrendingPlayers == true ? styles.tabText : [styles.tabText, { opacity: 0.5 }]}>Discover</Text>
          </Pressable>


        </View>
        <View style={{ marginVertical: 10,marginHorizontal:0, backgroundColor: theme.BACKGROUND_ONE }}>
          <DropDownPicker

            searchable={false}
            multiple={true}
            min={0}
            max={5}
            mode={"BADGE"}
            extendableBadgeContainer={true}
            closeAfterSelecting={true}
            badgeDotStyle={{
              borderRadius: 100
            }}
            badgeStyle={{
              padding: 5,
              borderRadius: 2
            }}
            badgeTextStyle={{
              fontStyle: "normal"
            }}
            listMode={"SCROLLVIEW"}
            placeholder={"SELECT YOUR FILTER"}
            underlineColor={theme.TEXT_COLOR}
            placeholderStyle={{
              color: theme.TEXT_COLOR,
              fontSize: 12
            }}
            listItemLabelStyle={{
              color: theme.TEXT_COLOR,

            }}
            style={{
              backgroundColor: theme.BACKGROUND_ONE,
              borderColor: theme.LINE_GRAY,
              borderRadius: 0,
            }}
            dropDownContainerStyle={{
              backgroundColor: theme.BACKGROUND_TWO,
              borderColor: theme.LINE_GRAY,

            }}
            textStyle={{
              fontSize: 14
            }}
            open={open}
            value={value}
            items={items}
            setOpen={setOpen}
            setValue={setValue}
            setItems={setItems}
            theme={colorScheme == "dark" ? "DARK" : "LIGHT"}
          />
        </View>
      </View>
    )
  }

  let barStyle = "dark-content";
  if (colorScheme == "dark") {
    barStyle = "light-content";
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle={barStyle}></StatusBar>
      {showTrendingPlayers && (
        <FlatList style={styles.container}
          data={trendingPlayers}
          keyExtractor={(item) => item.player_detail[0].id}
          renderItem={({ item }) => renderItem(item)}
          ListHeaderComponent={ListHeader}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
          stickyHeaderIndices={[0]}
        />
      )
      }

      {!showTrendingPlayers && (
        <FlatList style={styles.container}
          data={followingPlayers}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => renderItem(item)}
          ListHeaderComponent={ListHeader}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
          stickyHeaderIndices={[0]}
        />
      )
      }
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: theme.BACKGROUND_ONE,
  },
  listHeader: {
    flex: 1,
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.BACKGROUND_ONE,
  },
  text: {
    flex: 1,
    fontSize: 18,
    lineHeight: 25,
    fontWeight: "700",
    color: theme.TEXT_COLOR
  },
  horizontalAlign: {
    flexDirection: "row",
  },
  textDisabled: {
    flex: 1,
    fontSize: 18,
    color: theme.PLACEHOLDER_COLOR
  },
  tab: {
    backgroundColor: theme.NAVTAB_BG,
    width: "50%",
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 3,
    borderBottomColor: "red",
    borderLeftColor: theme.LINE_GRAY,
    borderRightColor: theme.LINE_GRAY,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tabDisabled: {
    backgroundColor: theme.BACKGROUND_TWO,
    width: "50%",
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: theme.LINE_GRAY,
    borderLeftColor: theme.LINE_GRAY,
    borderRightColor: theme.LINE_GRAY,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tabSearch: {
    backgroundColor: theme.NAVTAB_BG,
    width: "10%",
    height: 50,
    paddingLeft: 8,
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabText: {
    fontSize: 14,
    color: theme.TEXT_COLOR,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  itemContainer: {
    backgroundColor: theme.BACKGROUND_ONE,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  item: {
    padding: 10,
    fontSize: 18,
    minHeight: 44,
    backgroundColor: theme.BACKGROUND_ONE,
    width: "75%",
    justifyContent: "center",


  },
  itemText: {
    fontSize: 14,
    color: theme.PLACEHOLDER_COLOR,
    textTransform: "uppercase"
  },
  itemTextFollow: {
    fontSize: 18,
    color: theme.TEXT_COLOR
  },
  itemActionUnfollow: {
    backgroundColor: theme.UNFOLLOW_BUTTON,
    width: "20%",
    borderRadius: 60,
    paddingHorizontal: 10,
    paddingVertical: 5,
    height: 30,
    marginVertical: 5,
    marginRight: 5,
    borderWidth: 1,
    borderColor: theme.LINE_GRAY
  },
  itemActionFollow: {
    backgroundColor: theme.BACKGROUND_TWO,
    width: "20%",
    borderRadius: 60,
    paddingHorizontal: 10,
    paddingVertical: 5,
    height: 30,
    marginVertical: 5,
    marginRight: 5,
    borderWidth: 1,
    borderColor: theme.LINE_GRAY
  },
  itemActionFollowText: {
    fontSize: 10,
    color: theme.TEXT_COLOR,
    textAlign: 'center',
    textTransform: "uppercase",
    lineHeight: 20,
    fontWeight: "bold"
  },
  itemActionUnfollowText: {
    fontSize: 9,
    color: theme.TEXT_COLOR,
    textAlign: 'center',
    textTransform: "uppercase",
    lineHeight: 20,
    fontWeight: "bold"
  },
  trendingItem: {

    justifyContent: 'flex-start',
  },
  superTrendingItem: {

    flexGrow: 1,
    justifyContent: "center",

  },
  trending: {
    fontSize: 14,
    color: "#00d455",
    fontWeight: '800',
    paddingRight: 5,
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
  },
  superTrending: {
    fontSize: 16,
    color: "red",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: '700',
    paddingRight: 5,
    textTransform: "uppercase",

  },

})
