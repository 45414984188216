import React, { useState } from "react";
import { StyleSheet, Text, View, TextInput, TouchableOpacity } from "react-native";
import axios from 'axios';
import LoadingView from '../components/Loading';
import useStore from '../services/StoreService';
//colors
import colors from '../Colors'
const theme = colors.defaultTheme();

export default function Login({ navigation }) {

    const [email, setEmail] = useState("felipe@airrostudio.com");
    const [password, setPassword] = useState("airro");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const setUser = useStore((state) => state.setUser);

    const axios = require('axios').default;

    const sendPostRequest = async () => {

        try {

            setLoading(true)

            const dataLogin = { email: email, password: password }

            const req = await axios.post('https://api.sportsbox.network/api/auth/login', dataLogin);
            const response = req.data

            //console.log(response)

            setLoading("")

            if (response.success) {

                setErrorMessage("")

                setUser({
                    first: response.first,
                    last: response.last,
                    email: response.email,
                    authToken: response.authToken,
                    players: response.players,
                    isLoggedIn: true
                })
                navigation.navigate("Players List");

            } else {

                setErrorMessage(response.message)

            }

            

        } catch (error) {

            setErrorMessage(error.message)
            setLoading(false)
        }
        
    }

    if (loading != "") { return (<LoadingView message={loading} />) }

    return (

        <View style={styles.container}>
            {errorMessage != "" &&

                <Text>{errorMessage}</Text>

            }
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="Email"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    autoCapitalize="none"
                    onChangeText={(email) => setEmail(email)}
                />
            </View>

            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="Password"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    secureTextEntry={true}
                    onChangeText={(password) => setPassword(password)}
                />
            </View>
            <TouchableOpacity style={styles.loginBtn} onPress={() => sendPostRequest()}>
                <Text style={styles.loginText}>Login</Text>
            </TouchableOpacity>
            <View style={styles.signupContainer}>
                    <Text style={styles.text}>Don't have credentials?</Text>
                <TouchableOpacity onPress={() => navigation.push("Sign Up")}>
                    <Text style={styles.link}>Sign up</Text>
                </TouchableOpacity>
            </View>
        </View>


    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: "90%"
    },
    inputView: {
        backgroundColor: theme.BACKGROUND_TWO,
        height: 50,
        marginBottom: 20,
        alignSelf: 'stretch',
        borderWidth:1,
        borderColor:theme.INPUT_BORDER_COLOR
    },
    TextInput: {
        height: 50,
        padding: 10,
        fontSize: 16,
        alignSelf: 'stretch',
        color:theme.TEXT_COLOR
    },
    text: {
        fontSize: 18,
        padding: 5,
        color: theme.TEXT_COLOR
    },
    loginBtn: {

        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.PRIMARY_BLUE,
        alignSelf: 'flex-end',
        borderRadius:10,
        paddingVertical:10,
        paddingHorizontal:20
    },
    loginText: {
        fontSize: 14,
        color: "white",
        fontWeight: 'bold',
        textTransform:"uppercase"

    },
    title: {
        color: theme.TITLE_COLOR,
        fontSize: 35,
        padding: 50,
        textAlign: 'center'
      },
      userTitle: {
        color: theme.TITLE_COLOR,
        fontSize: 50,
        padding: 10,
        textAlign: 'center',
        textTransform: "capitalize"
      },
      signupContainer: {
        flexDirection: "row",
        justifyContent:"center",
        alignItems:"center",
        padding: 20,
        marginTop:20
    },
    link: {

        color: theme.PRIMARY_BLUE,
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 10,

    },
});

