import axios from "axios";

const searchPlayer = async (term) => {

        console.log("SEARCH WITH:", term)

        const response = await axios.get(`http://api.sportsbox.network/api/players/search?q=${term}` )
        let searchList = response.data.hits

        //console.log("Busca",searchList)

        return searchList
}

export default searchPlayer;