import React, { useState } from "react";
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Dimensions, SafeAreaView, StatusBar,Pressable } from "react-native";
import LoadingView from '../components/Loading';
import useStore from '../services/StoreService';
import signUp from "../services/SignUpService";
//assets
import colors from "../Colors"


const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

const theme = colors.defaultTheme()

export default function SignUp({ navigation }) {

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const setUser = useStore((state) => state.setUser);

    const sendPostRequest = async () => {

        try {
            setLoading(true)
            const res = await signUp(username, password, email);
            const response = res.data

            console.log(response)

            setLoading("")

            if (response.success) {

                setErrorMessage("")

                setUser({
                    email: response.email,
                    username: response.username,
                    authToken: response.authToken,
                    players: [],
                    isLoggedIn: true
                })
                navigation.navigate("Players List");
            } else {

                setErrorMessage(response.message)

            }



        } catch (error) {

            console.log(error)

            setErrorMessage(error.message)
            setLoading(false)
        }

    }

    if (loading != "") { return (<LoadingView message={loading} />) }

    return (


        <SafeAreaView style={styles.container}>
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Username"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    inputMode="text"
                    autoCapitalize='none'
                    onChangeText={(username) => setUsername(username)}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Email"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    autoCapitalize='none'
                    inputMode="email"
                    onChangeText={(email) => setEmail(email)}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Password"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    secureTextEntry={true}
                    onChangeText={(password) => setPassword(password)}
                />
            </View>
            <View style={styles.hAlign}>
                <View style={styles.separator}>
                    <Text style={[styles.text, { fontSize: 14, textAlign:"left" }]} >fields with * are mandatory</Text>
                </View>
                <TouchableOpacity style={styles.loginBtn} onPress={() => sendPostRequest()}>
                    <Text style={styles.loginText}>Sign up</Text>
                </TouchableOpacity>
            </View>
            {errorMessage != "" &&
                <View style={styles.separator}>
                    <Text style={styles.alert}>{errorMessage}</Text>
                </View>
            }
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        alignItems: 'center',
        justifyContent:"center",
        width: "90%",
        backgroundColor: theme.BACKGROUND_ONE,
        paddingTop:30,
      
    },
    separator: {
        flex:1,
        position: "relative",        
        maxHeight:70,
        marginTop:10
    },
    inputView: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 20,
        width: "100%",
        alignSelf: 'center',
        padding:10
    },
    inputViewArea: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        height: 100,
        width: "100%",
        alignSelf: 'center',
        padding:10
        
    },
    inputViewDouble: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 20,
        width: "49%",
        padding:10
    },
    TextInput: {
        fontSize: 18,
        alignSelf: 'center',
        width: "100%",
        color: theme.TEXT_COLOR

    },
    textArea: {
        width: "100%",
        alignSelf: "center",
        borderWidth: 0,
        minHeight: 100,
        maxHeight: 100,
        fontSize: 18,
        color: theme.TEXT_COLOR,
    },
    text: {
        fontSize: 16,
        color: theme.TEXT_COLOR,
        width: SCREEN_WIDTH * 0.90,
        paddingVertical:10,
        textAlign:"center",
 
    },
    terms: {
        fontSize: 14,
        color: theme.TEXT_COLOR, 
    },
    alert: {
        fontSize: 18,
        color: "#f55",
        paddingVertical: 30
    },
    loginBtn: {

        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.PRIMARY_BLUE,
        alignSelf: 'flex-end',
        borderRadius:10,
        paddingVertical:10,
        paddingHorizontal:20
    },
    loginText: {
        fontSize: 14,
        color: "white",
        fontWeight: 'bold',
        textTransform:"uppercase"

    },
    title: {
        color: theme.TITLE_COLOR,
        fontSize: 35,
        padding: 50,
        textAlign: 'center'
    },
    userTitle: {
        color: theme.TITLE_COLOR,
        fontSize: 50,
        padding: 10,
        textAlign: 'center',
        textTransform: "capitalize"
    },
    listHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 50
    },
    logoText: {
        fontSize: 42,
        fontWeight: "500",
        marginLeft: 5,
        height: 50,
        alignSelf: "center",
        color: theme.HEADER_TITLE_COLOR
    },
    signupContainer: {
        flexDirection: "row",
        padding: 20
    },
    link: {

        color: theme.BUTTON_ACTIVE_COLOR,
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 10

    },
    hAlign: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    horizontalAlign: {
        flexDirection: "row",
        justifyContent:"flex-start",
        marginTop:20

    },
    linkText: {

        color: theme.LOGIN_LINK,
        fontSize: 14,
        fontWeight: "bold",


    },
    checkbox:{
        marginRight:10
    }

});


