import axios from "axios";
const SERVER = "http://api.sportsbox.network";

const signUp = async (username, password, email) => {
    const dataSignUp = {
  
      password: password,
      email: email,
      username:username,

  
    }
  
    return await axios.post(SERVER + '/api/auth/signup', dataSignUp);
  }

  export default signUp