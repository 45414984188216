import { ActivityIndicator, View, StyleSheet } from 'react-native';
//colors
import colors from '../Colors'
const theme = colors.defaultTheme();

export default function LoadingView(props) {

    return (

        <View style={[styles.container, styles.horizontal]}>
            <ActivityIndicator size="large" color={theme.PRIMARY_BLUE} />
        </View>

    )

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loading: {
        fontSize: 22,
        color: theme.TEXT_COLOR,
        alignSelf: 'center',
        marginRight: 10
    },
    horizontal: {
        flexDirection: "row",
    }
});
