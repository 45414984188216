function saveUserDataToSharedStorage(data) {}

function loadUserDataFromSharedStorage() {}

function initBackgroundTask(updateWidgetInfo) {}

function reloadWidget() {}

module.exports = {
  saveUserDataToSharedStorage,
  loadUserDataFromSharedStorage,
  reloadWidget,
  initBackgroundTask,
};
