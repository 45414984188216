import axios from "axios";

const follow = (playerId, authToken) => {

    //console.log("Player ID",  playerId)
   // console.log("Auth Token",  authToken)

    return axios.post(`http://api.sportsbox.network/api/auth/follow/player/${playerId}`,{},
    {
        headers:{
            Authorization: `Bearer ${authToken}`
        }
    })
}

const unfollow = (playerId, authToken) => {

    //console.log("Player ID",  playerId)
    //console.log("Auth Token",  authToken)

    return axios.post(`http://api.sportsbox.network/api/auth/unfollow/player/${playerId}`,{},
    {
        headers:{
            Authorization: `Bearer ${authToken}`
        }
    })
}

export {follow, unfollow};