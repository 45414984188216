import React, { useState, useCallback } from "react"
import { StyleSheet, Text, View, TextInput, TouchableOpacity, FlatList, Pressable, Button, SafeAreaView } from "react-native"
import LoadingView from '../components/Loading'
import useStore from '../services/StoreService'
import searchPlayer from '../services/SearchService'
import { follow, unfollow } from "../services/PlayerService"
import { MaterialIcons } from '@expo/vector-icons'
import {debounce} from 'lodash'
//colors
import colors from '../Colors'
const theme = colors.defaultTheme();

export default function Search({ navigation }) {


    const [search, setSearch] = useState([]);
    const [term, setTerm] = useState(null);
    const [loading, setLoading] = useState(false)

    const authToken = useStore((state) => state.authToken)
    const loggedIn = useStore((state) => state.isLoggedIn)
    const playerExists = useStore((state) => state.playerExists)
    const addPlayer = useStore((state) => state.addPlayer)
    const removePlayer = useStore((state) => state.removePlayer)

   

    function separator() {
        return (

            <View style={{
                height: 1,
                width: "100%",
                backgroundColor: theme.BACKGROUND_TWO,
            }}
            />
        )
    }

    function renderItem(item) {

        const player = item._source

        return (

            <View style={styles.itemContainer}>
            <TouchableOpacity onPress={() => navigation.push('Player Profile', player)} style={styles.item}>
                    <Text style={styles.itemText}>{player.name}</Text>
                </TouchableOpacity>
                {loggedIn && playerExists(player.id) && (

                    <Pressable style={styles.itemActionUnfollow}
                        onPress={async () => {
                            try {
                                const { data } = await unfollow(player.id, authToken)
                                removePlayer(data.player) 
                                reloadSearchList()                                                     
                            } catch (e) {
                                console.log(e)
                            }
                        }}>
                        <Text style={styles.itemActionUnfollowText}>unfollow</Text>
                    </Pressable>
                )
                }
                {loggedIn && !playerExists(player.id) && (
                
                    <Pressable style={styles.itemActionFollow}
                        onPress={async () => {
                            try {
                                const { data } = await follow(player.id, authToken)
                                addPlayer(data.player)
                                reloadSearchList()  
                            } catch (e) {
                                console.log(e)
                            }
                        }}>
                        <Text style={styles.itemActionFollowText}>follow</Text>
                    </Pressable>
                )
                }
            </View>
        )
    }

    async function searchAction(value) {

        const wordForSearch = await searchPlayer(value)

        setTerm(value)
        setSearch(wordForSearch)        

    }

    function  handleChange(value){

        console.log(value)
        setTerm(value)
        handleSearch(value)

      };

    const handleSearch = useCallback(
        debounce((term) => {

            //console.log("SEARCH with debounce")
            searchAction(term)
        }, 500),
        []
    )

    function reloadSearchList(){

        searchAction(term) 
    }


    return (

        <SafeAreaView style={styles.container}>
            <View style={styles.listHeader}>
                <View style={styles.tab}>
                    <TextInput
                        style={styles.textInput}
                        placeholder="Enter the Player's Name"
                        onChangeText={(value) => handleChange(value)}
                    />
                </View>
                <TouchableOpacity style={styles.button} onPress={() => searchAction(term)}>
                    <MaterialIcons name="search" size={28} color={"white"} />
                </TouchableOpacity>
            </View>
            <FlatList style={styles.container}
                data={search}
                keyExtractor={(item) => item._id}
                ItemSeparatorComponent={separator}
                renderItem={({ item }) => renderItem(item)}
                initialNumberToRender={10}
                windowSize={12}
        

            />
        </SafeAreaView>

    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        width:"100%",     
    },
    listHeader: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.BACKGROUND_ONE,
        height: 50,
        marginVertical:10,
        width:"90%",
        alignSelf:"center"

    },
    text: {
        flex: 1,
        fontSize: 18,
        color: theme.TEXT_COLOR
    },
    tab: {
        backgroundColor: theme.BACKGROUND_TWO,
        height: 50,
        width:"85%",
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR
    },    
    btnSearch: {
        backgroundColor: theme.LINE_GRAY,
        width: "15%",
        height: 50,
        paddingLeft: 8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    textInput: {
        fontSize: 18,
        color: theme.TEXT_COLOR,
        padding: 10,
        height: 50
    },
    itemContainer: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        justifyContent: "space-between",
        alignItems: "stretch",
        flexDirection: "row",
        width:"90%",
        alignSelf:"center"
    },
    item: {
        paddingVertical: 10,
        fontSize: 18,
        height: 44,
        backgroundColor: theme.BACKGROUND_ONE,
        width: "75%"

    },
    itemText: {
        fontSize: 14,
        color: theme.PLACEHOLDER_COLOR,
        textTransform: "uppercase"
      },
      itemTextFollow: {
        fontSize: 18,
        color: theme.TEXT_COLOR
      },
      itemActionUnfollow: {
        backgroundColor: theme.UNFOLLOW_BUTTON,
        width: "20%",
        borderRadius: 60,
        paddingHorizontal: 10,
        paddingVertical: 5,
        height: 30,
        marginVertical: 5,
        marginRight: 5,
        borderWidth: 1,
        borderColor: theme.LINE_GRAY
      },
      itemActionFollow: {
        backgroundColor: theme.BACKGROUND_TWO,
        width: "20%",
        borderRadius: 60,
        paddingHorizontal: 10,
        paddingVertical: 5,
        height: 30,
        marginVertical: 5,
        marginRight: 5,
        borderWidth: 1,
        borderColor: theme.LINE_GRAY
      },
      itemActionFollowText: {
        fontSize: 10,
        color: theme.TEXT_COLOR,
        textAlign: 'center',
        textTransform: "uppercase",
        lineHeight: 20,
        fontWeight: "bold"
      },
      itemActionUnfollowText: {
        fontSize: 9,
        color: theme.TEXT_COLOR,
        textAlign: 'center',
        textTransform: "uppercase",
        lineHeight: 20,
        fontWeight: "bold"
      },
    trendingItem: {
        flex: 1,
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    trending: {
        fontSize: 18,
        color: theme.TEXT_COLOR,
        fontWeight: 'bold',
        paddingRight: 5
    },
    superTrending: {
        fontSize: 18,
        color: theme.TEXT_COLOR,
        fontWeight: '700',
        paddingRight: 5
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.PRIMARY_BLUE,
        alignSelf: 'center',
        borderRadius:0,
        paddingVertical:10,
        paddingHorizontal:20, 
        borderTopRightRadius:10,
        borderBottomRightRadius:10,
    },
    buttonText: {
        fontSize: 14,
        color: "white",
        fontWeight: 'bold',
        textTransform:"uppercase"
    
    },
    
})


